import React from "react";
import { Helmet } from "react-helmet";
import HeroSection from "../components/uniglobe/landingpage/HeroSection";
import BrowseProjects from "../components/uniglobe/landingpage/Projects";
import WhyChoose from "../components/uniglobe/landingpage/WhyChoose";
import Discover from "../components/uniglobe/landingpage/Discover";
import StatsSection from "../components/uniglobe/landingpage/StatsSection";
import Carousel from "../components/uniglobe/landingpage/Carasoul";
import Testimonials from "../components/uniglobe/landingpage/Testimonials";
import UniGlobeMarNav from "../common/unimarnav/UniGlobeMarNav";
import About from "../components/uniglobe/landingpage/About";
import UniMarFooter from "../common/footer/UniMarFooter";
import HowWeWork from "../components/uniglobe/landingpage/Work";
import Client from "../components/uniglobe/landingpage/Client";
import Company from "../components/uniglobe/landingpage/Company";

const Home = () => {
  return (
    <div>
      <Helmet>
        <title>UniGlobe Marketing</title>
        <link rel="icon" href="/favicon-home.ico" />
      </Helmet>
      <UniGlobeMarNav />
      <HeroSection />
      <StatsSection />
      <About />
      <Discover />
      <WhyChoose />
      <HowWeWork />
      <Client />
      {/* <Company /> */}
      {/* <BrowseProjects /> */}
      <UniMarFooter />
    </div>
  );
};

export default Home;
