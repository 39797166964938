import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiPhoneCall } from "react-icons/fi";
import logo from "../../assets/uniglobemar/uniglobemarketinglogo.svg"
import "../../styles/uniglobemar.css";

const UniGlobeMarNav = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  // Navigation links object
  const navLinks = [
    { name: "BUSINESS", href: "#business" },
    { name: "ABOUT", href: "#about" },
    { name: "SERVICES", href: "#services" },
    { name: "EXPERTISE", href: "#experties" },
    { name: "WORK", href: "#work" },
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`position-fixed w-100 navbar navbar-expand-lg p-3 ${
        isScrolled ? "navbar-scrolled" : "navbar-unimar-bg"
      }`}
      style={{ zIndex: "10000" }}
    >
      <div className="container">
        <Link to="/">
          <img
            src={logo}
            alt="Uniglobe Marketing"
            className="logo-uni-mar"
          />
        </Link>

        {/* Collapsible menu */}
        <div className="collapse navbar-collapse" id="navbarNav">
          <div className="d-flex w-100 justify-content-between align-items-center">
            {/* Dynamically Rendered Navigation Links */}
            <ul className="navbar-nav mx-auto gap-5">
              {navLinks.map((link, index) => (
                <li className="nav-item" key={index}>
                  <a className="nav-link text-white nav_text" href={link.href}>
                    {link.name}
                  </a>
                </li>
              ))}
            </ul>

            <a href="tel:03266619997">
              <p className="text-white mb-0 me-4">
                <FiPhoneCall className="me-1" />
                +92 326 6619997
              </p>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default UniGlobeMarNav;
