import React from "react";
import Slider from "react-slick";
import icon1 from "../../../assets/uniglobemar/offericon/vector_7310553 1.png";
import icon2 from "../../../assets/uniglobemar/offericon/bullhorn_1997928 1 (1).png";
import icon3 from "../../../assets/uniglobemar/offericon/graphic-tablet_548315 1.png";
import icon4 from "../../../assets/uniglobemar/offericon/metadata_18294516 1.png";
import { BsArrowLeft } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Discover = () => {
  const settings = {
    dots: false,
    infinite: true,
    autoPlay: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024, // For tablets
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768, // For mobile
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480, // For smaller screens
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const services = [
    { title: "Graphic Design", image: icon1, para: "Stunning graphic design that captures attention and elevates your brand’s presence." },
    { title: "Digital Marketing", image: icon2, para: "Elevating your business with targeted strategies and measurable results in digital marketing.    " },
    { title: "UI/UX Design", image: icon3, para: "Transforming ideas into user-centric designs that deliver exceptional experiences and results.    " },
    { title: "Web Development", image: icon4, para: "Custom web development solutions that drive results and create engaging user experiences.    " },
  ];

  return (
    <div className="p-lg-5 p-2" style={{ backgroundColor: "#F6F4F9" }}
    id="services"
    >
      <div className="container py-5 position-relative">
        <div className="text-center mb-5">
          <h2 className="mar_head text-center" data-aos="fade-up">What We’re Offering</h2>
        </div>
        <Slider {...settings}>
          {services.map((service, index) => (
            <div className="px-2" key={index}>
              <div className="card_b_mar text-center p-3 mb-3" data-aos="fade-down">
                <div className="mx-auto mt-4 mb-4 d-flex justify-content-center align-items-center">
                  <img src={service.image} alt={service.title} className="zoom-icon" />
                </div>
                <h5 className="fw-bold">{service.title}</h5>
                <p className="para_mar text-center">
                {service.para}
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

// Custom Arrows
const PrevArrow = ({ onClick }) => (
  <button className="custom-prev-dis" onClick={onClick}>
    {/* &#8592; */}
    <BsArrowLeft />

  </button>
);

const NextArrow = ({ onClick }) => (
  <button className="custom-next-dis" onClick={onClick}>
    {/* &#8594; */}
    <BsArrowRight />

  </button>
);

export default Discover;
