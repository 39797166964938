import React, { useState } from "react";
import { toast } from "react-hot-toast";

const InquiryForm = () => {
  // State to store form values
  const [formData, setFormData] = useState({
    fullName: "",
    mobileNumber: "",
    email: "",
    interestedArea: "",
    preferredArea: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    // if (name === "mobileNumber") {
    //   const mobileNumberPattern = /^(?:\+92|0)3[0-9]{0,9}$/;
    //   if (!mobileNumberPattern.test(value)) {
    //     toast.error("Please enter a valid mobile number format.");
    //   }
    // }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  // Handler to update form values
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  // Form submit handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    const mobileNumberPattern = /^(\+?\d{1,3}[-.\s]?)?(?:\(?\d{1,4}\)?[-.\s]?)?\d{3,4}[-.\s]?\d{4}$/;
    if (!mobileNumberPattern.test(formData.mobileNumber)) {
      toast.error(
        "Invalid mobile number. Please use a valid format, such as +923266619997, 03266619997, +92 326 6619997, 0326 6619997, or an international format like +1234567890."
      );
      return;
    }


    try {
      const baseURL = process.env.REACT_APP_BASE_URL;
      const response = await fetch(`${baseURL}/api/inquiry/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      // const response = await fetch(
      //   "https://be.uniglobemarketing.com/api/inquiry/",
      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify(formData),
      //   }
      // );

      // console.log(response);
      if (!response.ok) {
        throw new Error("Failed to submit form");
      }
      toast.success("Form submitted successfully!");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="container py-4 px-0 mt-lg-5 mt-0 pt-lg-5 pt-0">
      <div className="card shadow p-4">
        <h2 className="fw-bold mb-1">Real Estate Inquiry Form</h2>
        <p className="text-muted">
          Please provide the following information to help us better understand
          your real estate needs.
        </p>

        {/* Form starts here */}
        <form onSubmit={handleSubmit}>
          <h5 className="fw-bold">Your Information</h5>

          {/* Full Name Input */}
          <div className="mb-1">
            <label htmlFor="fullName" className="form-label">
              Full Name
            </label>
            <input
              type="text"
              className="form-control"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '');
              }}
              onChange={handleChange}
              required
            />
            {/* <input
              type="text"
              className="form-control"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              required
            /> */}
          </div>

          {/* Mobile Number Input */}
          <div className="mb-1">
            <label htmlFor="mobileNumber" className="form-label">
              Mobile Number
            </label>
            {/* <input
              type="text"
              className="form-control"
              id="mobileNumber"
              name="mobileNumber"
              value={formData.mobileNumber}
              onChange={handleChange}
              required
            /> */}
            <input
              type="text"
              className="form-control"
              id="mobileNumber"
              name="mobileNumber"
              value={formData.mobileNumber}
              onChange={handleChange}
              onKeyDown={(e) => {
                // Allow only numeric keys, backspace, delete, and other necessary keys
                const allowedKeys = [
                  "Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab", "+", " ", "-",
                ];
                if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
                  e.preventDefault();
                }
              }}
              required
            />

          </div>

          {/* Email Input */}
          <div className="mb-1">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <h5 className="fw-bold mt-3">Your Preferences</h5>

          {/* Interested Area */}
          <div className="mb-2">
            <label className="form-label d-block fw-bold">
              Interested Area
            </label>
            {["3 Marla", "4 Marla", "5 Marla", "10 Marla"].map((area) => (
              <div className="form-check form-check-inline" key={area}>
                <input
                  className="form-check-input"
                  type="radio"
                  id={area}
                  name="interestedArea"
                  value={area}
                  onChange={handleChange}
                  required
                />
                <label className="form-check-label" htmlFor={area}>
                  {area}
                </label>
              </div>
            ))}
          </div>

          {/* Preferred Area */}
          {/* <label className="form-label d-block fw-bold">Preferred Area</label>
          <div className="d-flex gap-lg-3 gap-2 mb-3">
            {["Corner Plot", "Facing Park", "General"].map((preference) => (
              <div className="form-check" key={preference}>
                <input
                  className="form-check-input"
                  type="radio"
                  id={preference}
                  name="preferredArea"
                  value={preference}
                  onChange={handleChange}
                  required
                />
                <label className="form-check-label" htmlFor={preference}>
                  {preference}
                </label>
              </div>
            ))}
          </div> */}

          {/* Submit Button */}
          <div className="d-grid mt-4">
            <button type="submit" className="border-0 btn-uni-main btn-lg d-flex align-item-center text-center justify-content-center">
              Get Inquiry
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InquiryForm;
