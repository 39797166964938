import React, { useEffect, useState } from "react";

import plot from "../../../data/property.plots.json";
import { toast } from "react-hot-toast";

const VerifyPayment = ({ form, setForm, nextStep, prevStep }) => {
  const [coinPrice, setCoinPrice] = useState();

  const handleIndex = () => {
    switch (form.plot) {
      case 3:
        return 0;
      case 4:
        return 1;
      case 5:
        return 2;
      case 10:
        return 3;
      default:
        return 0;
    }
  };

  // const handleCalculatePrice = (price) => {
  //   let plotPrice = 0;
  //   if (form.paymentType === "Full Payment") {
  //     plotPrice = plot[handleIndex()].payment_plans.total_price / price;
  //   } else {
  //     plotPrice = plot[handleIndex()].payment_plans.down_payment / price;
  //   }
  //   setForm((prevState) => ({
  //     ...prevState,
  //     totalPayment: plotPrice,
  //   }));
  // };

  const handleBookPlot = async (event) => {
    event.preventDefault();
    const phoneNumberPattern = /^(\+?\d{1,3}[-.\s]?)?(?:\(?\d{1,4}\)?[-.\s]?)?\d{3,4}[-.\s]?\d{4}$/;
    if (!phoneNumberPattern.test(form.phoneNumber)) {
      toast.error(
        "Invalid mobile number. Please use a valid format, such as +923266619997, 03266619997, +92 326 6619997, 0326 6619997, or an international format like +1234567890."
      );
      return;
    }
    const formData = new FormData();
    formData.append("fullName", form.fullName);
    formData.append("phoneNumber", form.phoneNumber);
    formData.append("cnicNumber", form.cnicNumber);
    formData.append("email", form.email);
    formData.append("paymentMethod", form.paymentMethod);
    formData.append("preferredArea", form.preferredArea);
    formData.append("plot", form.plot);
    formData.append("paymentType", form.paymentType);
    formData.append("transactionHash", form.transactionHash);
    formData.append("screenShot", form.screenShot);
    formData.append("totalPayment", coinPrice);

    try {
      const baseURL = process.env.REACT_APP_BASE_URL;
      const response = await fetch(`${baseURL}/api/bookings`, {
        method: "POST",
        body: formData,
      });
      // const response = await fetch(
      //   "https://be.uniglobemarketing.com/api/bookings",
      //   {
      //     method: "POST",
      //     body: formData,
      //   }
      // );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to submit payment");
      }

      // Reset form state
      setForm({
        fullName: "",
        phoneNumber: "",
        cnicNumber: "",
        paymentMethod: "",
        paymentType: "",
        installmentPlan: "",
        transactionHash: "",
        screenShot: null,
      });

      toast.success("Payment successful! Booking confirmed.");
      nextStep();
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  // const handleGetPrice = async () => {
  //   try {
  //     const price = sessionStorage.getItem("vrcprice");
  //     if (price) {
  //       setCoinPrice(parseFloat(price));
  //       handleCalculatePrice(parseFloat(price));
  //       return;
  //     }
  //     const response = await fetch(
  //       "https://api.coingecko.com/api/v3/simple/price?ids=virtual-coin&vs_currencies=pkr"
  //     );
  //     const data = await response.json();
  //     sessionStorage.setItem("vrcprice", data["virtual-coin"].pkr.toString());
  //     setCoinPrice(data["virtual-coin"].pkr);
  //     handleCalculatePrice(data["virtual-coin"].pkr);
  //   } catch (error) {
  //     alert(`Error: ${error}`);
  //   }
  // };

  const handleGetPrice = async () => {
    try {
      const baseURL = process.env.REACT_APP_BASE_URL;
      const response = await fetch(`${baseURL}/api/vrc/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      // console.log("responseresponseresponse GET",response.json())
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (!data?.data || !Array.isArray(data.data)) {
        throw new Error("Unexpected API response structure.");
      }
      const plotData = data.data.find((item) => item.marla === form.plot);
      if (!plotData || !plotData.payment_plans) {
        throw new Error("Plot data or payment plans not found.");
      }
      let price;
      // console.log("form.paymentMethod",form.paymentMethod)
      if (form.paymentType === "Full Payment") {
        price = plotData.payment_plans.total_price;
      } else {
        price = plotData.payment_plans.down_payment;
      }
      setCoinPrice(price);
    } catch (error) {
      toast.error(`Payment Plan not found ${form.plot} with VRC`);
    }
  };

  useEffect(() => {
    handleGetPrice();
  }, []);

  return (
    <form className="space-y-4 p-lg-4 p-0" onSubmit={handleBookPlot}>
      {/* Full Name Input */}
      <div className="mb-3">
        <label htmlFor="fullName" className="form-label">
          Full Name:
        </label>
        <input
          type="text"
          className="form-control"
          id="fullName"
          name="fullName"
          value={form.fullName || ""}
          onChange={(e) => {
            const value = e.target.value.replace(/[^a-zA-Z\s]/g, ''); // Remove invalid characters
            setForm({ ...form, fullName: value }); // Update state with valid value
          }}
          required
        />

        {/* <input
          type="text"
          className="form-control"
          id="fullName"
          name="fullName"
          value={form.fullName || ""}
          onChange={(e) => setForm({ ...form, fullName: e.target.value })}
          required
        /> */}
      </div>

      {/* Phone Number Input */}
      <div className="mb-3">
        <label htmlFor="phoneNumber" className="form-label">
          Phone Number:
        </label>
        {/* <input
          type="text"
          className="form-control"
          id="phoneNumber"
          name="phoneNumber"
          value={form.phoneNumber || ""}
          onChange={(e) => setForm({ ...form, phoneNumber: e.target.value })}
          required
          // pattern="^(\+92|0)3[0-9]{9}$"
          // title="Enter a valid Pakistani phone number (e.g., +923001234567 or 03001234567)"
        /> */}
        <input
          type="text"
          className="form-control"
          id="mobileNumber"
          name="mobileNumber"
          value={form.phoneNumber || ""}
          onChange={(e) => setForm({ ...form, phoneNumber: e.target.value })}
          onKeyDown={(e) => {
            // Allow only numeric keys, backspace, delete, and other necessary keys
            const allowedKeys = [
              "Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab", "+", " ", "-",
            ];
            if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
              e.preventDefault();
            }
          }}
          required
        />
      </div>

      {/* CNIC Number Input */}
      <div className="mb-3">
        <label htmlFor="cnicNumber" className="form-label">
          CNIC Number:
        </label>
        <input
          type="text"
          className="form-control"
          id="cnicNumber"
          name="cnicNumber"
          value={form.cnicNumber || ""}
          onChange={(e) => {
            const input = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
            let formattedCnic = input;

            if (input.length > 5 && input.length <= 12) {
              formattedCnic = `${input.slice(0, 5)}-${input.slice(5)}`;
            } else if (input.length > 12) {
              formattedCnic = `${input.slice(0, 5)}-${input.slice(
                5,
                12
              )}-${input.slice(12)}`;
            }

            setForm({ ...form, cnicNumber: formattedCnic });
          }}
          required
          maxLength="15" // Limits total input length, including hyphens
          pattern="^[0-9]{5}-[0-9]{7}-[0-9]$"
          title="Enter a valid CNIC number (e.g., 12345-1234567-1)"
        />
      </div>

      <div className="mb-3">
        <label htmlFor="email" className="form-label">
          Email
        </label>
        <input
          type="email"
          className="form-control"
          id="email"
          name="email"
          value={form.email || ""}
          onChange={(e) => {
            setForm({ ...form, email: e.target.value });
          }}
          required
        />
      </div>

      <div>
        <h4>
          You have to pay a total of <span> {coinPrice}</span> VRC
        </h4>
      </div>

      {/* Transaction Hash Input */}
      <div className="mb-3">
        <label htmlFor="transactionHash" className="form-label">
          Transaction hash:
        </label>
        <input
          type="text"
          className="form-control"
          id="transactionHash"
          name="transactionHash"
          value={form.transactionHash || ""}
          onChange={(e) =>
            setForm({ ...form, transactionHash: e.target.value })
          }
          required
          pattern="^(https?://.*|0x[a-fA-F0-9]+)$"
          title="Enter a valid transaction hash starting with 0x (e.g., 0xa3f4e6d7...) or a full URL containing the hash."
        />


        {/* <input
          type="text"
          className="form-control"
          id="transactionHash"
          name="transactionHash"
          value={form.transactionHash || ""}
          onChange={(e) =>
            setForm({ ...form, transactionHash: e.target.value })
          }
          required
          pattern="^[a-fA-F0-9]{64}$"
          title="Enter a valid 64-character hexadecimal transaction hash (e.g., a3f4e6d7...)"
        /> */}
      </div>

      {/* Screenshot Upload */}
      <div className="mb-3">
        <label htmlFor="screenShot" className="form-label">
          Upload Payment Screenshot:
        </label>
        <input
          type="file"
          className="form-control"
          id="screenShot"
          name="screenShot"
          onChange={(e) => setForm({ ...form, screenShot: e.target.files[0] })}
          required
        />
      </div>

      {/* Buttons */}
      <div className="d-flex gap-3 justify-content-between">
        <button
          type="button"
          className="btn btn-secondary w-100 fs-5"
          onClick={prevStep}
        >
          Back
        </button>
        <button type="submit" className="btn btn-uni-main w-100">
          Submit
        </button>
      </div>
    </form>
  );
};

export default VerifyPayment;
