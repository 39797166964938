import React, { useState } from "react";
import PaymentMethod from "./PaymentMethod";
import VerifyPayment from "./VerifyPayment";

const Booking = ({ setBooking }) => {

  const [form, setForm] = useState({
    fullName: "",
    phoneNumber: "",
    cnicNumber: "",
    email: "",
    paymentMethod: "VRC",
    preferredArea: "",
    paymentType: "",
    plot: null, 
    transactionHash: "",
    screenShot: null,
    totalPayment: null,
  });

  const [currentStep, setCurrentStep] = useState(1);

  const nextStep = () => setCurrentStep((prevStep) => prevStep + 1);
  const prevStep = () => {setCurrentStep((prevStep) => prevStep - 1);
    setForm(
      {
        fullName: "",
        phoneNumber: "",
        cnicNumber: "",
        email: "",
        paymentMethod: "VRC",
        preferredArea: "",
        paymentType: "",
        plot: null,
        transactionHash: "",
        screenShot: null,
        totalPayment: null,
      }
    )
  }

  return (
    <div className="container my-2">
      {/* <ol className="d-flex justify-content-between">
        <li
          className={`d-flex align-items-center ${
            currentStep >= 1 ? "step-active" : "step-inactive"
          }`}
        >
          <span
            className={`d-flex justify-content-center align-items-center rounded-circle p-2 ${
              currentStep >= 1 ? "bg-primary" : "bg-secondary"
            }`}
          >
            1
          </span>
        </li>
        <li
          className={`d-flex align-items-center ${
            currentStep >= 2 ? "step-active" : "step-inactive"
          }`}
        >
          <span
            className={`d-flex justify-content-center align-items-center rounded-circle p-2 ${
              currentStep >= 2 ? "bg-primary" : "bg-secondary"
            }`}
          >
            2
          </span>
        </li>
        <li
          className={`d-flex align-items-center ${
            currentStep >= 3 ? "step-active" : "step-inactive"
          }`}
        >
          <span
            className={`d-flex justify-content-center align-items-center rounded-circle p-2 ${
              currentStep >= 3 ? "bg-primary" : "bg-secondary"
            }`}
          >
            3
          </span>
        </li>
      </ol> */}

      {currentStep === 1 && (
        <PaymentMethod
          form={form}
          setForm={setForm}
          nextStep={nextStep}
          prevStep={prevStep}
          setBooking={setBooking}
        />
      )}
      {currentStep === 2 && (
        <VerifyPayment
          form={form}
          setForm={setForm}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      )}
      {currentStep === 3 && (
        <div className="d-flex flex-column">
          <h2 className="text-center px-5 mb-5">
            Thank you for booking your property. We have sent a confirmation
            email to {form.email} with transaction details.
          </h2>
          <button
            className="btn btn-uni-main mx-auto"
            onClick={() => setBooking(false)}
          >
            Close
          </button>{" "}
        </div>
      )}
    </div>
  );
};

export default Booking;
