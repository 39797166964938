import React, { useState } from "react";
import icon1 from "../../../assets/uniglobemar/clients/University Home Green logo 3.png";
import icon1Hover from "../../../assets/uniglobemar/clients/University Homes-01 1.png";
import icon2 from "../../../assets/uniglobemar/clients/new-logo 2.png";
import icon2Hover from "../../../assets/uniglobemar/clients/Union Complex LOgo-01 1.png";
import icon3 from "../../../assets/uniglobemar/clients/Justouch-07 1.png";
import icon3Hover from "../../../assets/uniglobemar/clients/Justouch-01 1.png";

const Client = () => {
    const [hoverIndex, setHoverIndex] = useState(null);

    const services = [
        { title: "Graphic Design", image: icon1, hoverImage: icon1Hover },
        { title: "Digital Marketing", image: icon2, hoverImage: icon2Hover },
        { title: "Ui Ux Design", image: icon3, hoverImage: icon3Hover },
    ];

    return (
        <div className="container py-5 position-relative" data-aos="fade-up">
            <div className="text-center mb-5">
                <h2 className="mar_head text-center">Our Client</h2>
            </div>
            <div className="row justify-content-center align-items-center">
                {services.map((service, index) => (
                    <div className="col-md-3" key={index}>
                        <div
                            className="card_b_mar_ text-center p-2 mb-3"
                            onMouseEnter={() => setHoverIndex(index)}
                            onMouseLeave={() => setHoverIndex(null)}
                            style={{cursor:"pointer"}}
                        >
                            <div className="mx-auto mt-4 mb-4 d-flex justify-content-center align-items-center">
                                <img
                                    src={hoverIndex === index ? service.hoverImage : service.image}
                                    alt={service.title}
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Client;
