import React from 'react'
import about from "../../../assets/uniglobemar/about.png"
const About = () => {
  return (
    <div className='bg_about p-3 mt-lg-5 mt-0 mb-lg-5 mb-0'
    id="about"
    >
    <div className='container'>
      <div className='row pt-5 pb-5 align-items-center'>
        <div className='col-lg-6 col-12' data-aos="fade-down">
         <img src={about} alt="" className='img-fluid'/>
        </div>
        <div className='col-lg-6 col-12 para_mar'>
            <h1 className='mar_head text-start' >About Us</h1>
            <p className='mb-2' data-aos="fade-up">
            Uniglobe is a seasoned digital marketing agency with several years of experience delivering exceptional results for businesses worldwide. We specialize in creating tailored marketing strategies that drive growth, enhance brand visibility, and generate measurable outcomes. As a result-driven company, we focus on leveraging the latest tools, trends, and innovations to ensure our clients achieve their goals efficiently. 

            </p>
            <p className='mb-0' data-aos="fade-up">
            At Uniglobe, we believe in building lasting partnerships, fostering creativity, and adapting to the dynamic digital landscape. Transform your vision into reality and take your business to new heights with UniGlobe. Together, let’s shape the future of your success!

            </p>
            {/* <button className="btn btn-main-mar d-flex justify-content-center mt-2">
              Discover More
            </button> */}
            </div>
      </div>
    </div>
    </div>
  )
}

export default About 
