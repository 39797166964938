// import React, { useState, useEffect } from 'react';
// import { motion } from 'framer-motion';

// const LettersPullUp = ({ text }) => {
//   const splittedText = text.split(' '); // Split by space for words
//   const [animate, setAnimate] = useState(true);

//   // Loop animation logic to restart the animation after a pause
//   useEffect(() => {
//     const interval = setInterval(() => {
//       setAnimate(false);
//       setTimeout(() => setAnimate(true), 500); // Restart animation after a brief pause
//     }, 4000);

//     return () => clearInterval(interval);
//   }, []);

//   const pullupVariant = {
//     initial: { y: 10, opacity: 0 },
//     animate: (i) => ({
//       y: 0,
//       opacity: 1,
//       // transition: {
//       //   delay: i * 0.05,
//       //   duration: 0.5,
//       //   type: 'spring',
//       //   stiffness: 100,
//       // },
//       transition: {
//         delay: i * 0.2, // Slower delay between each word (was 0.05)
//         duration: 0.7,  // Slightly longer animation duration (was 0.5)
//         type: 'spring',
//         stiffness: 80,  // Adjusted stiffness for smoother motion
//       },
//     }),
//   };

//   return (
//     <div className="text-center">
//           <div className="d-inline-flex flex-wrap justify-content-center">
//         {splittedText.map((word, i) => (
//           <React.Fragment key={i}>
//             <motion.span
//               variants={pullupVariant}
//               initial="initial"
//               animate={animate ? 'animate' : 'initial'}
//               custom={i}
//               className={`main_text_mar ms-4 ${word === 'A' ? 'me-3' : ''}`}
//             >
//               {word}
//             </motion.span>
//             {/* Add line break after "A" */}
//             {word === 'A' && <div className="w-100"></div>}
//           </React.Fragment>
//         ))}
//       </div>

//     </div>
//   );
// };

// export default LettersPullUp;



import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const LettersPullUp = ({ text }) => {
  const [animateKey, setAnimateKey] = useState(0); // Key to reset the animation
  const words = text.split(' '); // Split text into words

  useEffect(() => {
    // Loop the animation every 6 seconds (adjust timing as needed)
    const interval = setInterval(() => {
      setAnimateKey((prev) => prev + 1); // Trigger re-render to restart animation
    }, 6000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const pullupVariant = {
    hidden: { y: 10, opacity: 0 },
    visible: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: i * 0.1, // Delay between letters
        duration: 0.5,  // Duration for each letter animation
        type: 'spring',
        stiffness: 100, // Smooth spring motion
      },
    }),
  };

  return (
    <div className="text-center">
      {words.map((word, wordIndex) => (
        <React.Fragment key={`${word}-${animateKey}-${wordIndex}`}>
          <div className="d-inline-flex flex-wrap justify-content-center me-3">
            {word.split('').map((letter, letterIndex) => (
              <motion.span
                key={`${letter}-${wordIndex}-${letterIndex}`}
                variants={pullupVariant}
                initial="hidden"
                animate="visible"
                custom={wordIndex * 10 + letterIndex} // Create unique delay for each letter
                className="main_text_mar fw-bold"
              >
                {letter}
              </motion.span>
            ))}
          </div>
          {/* Add a line break after the word "A" */}
          {word === 'A' && <br />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default LettersPullUp;
