import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

const WhyChoose = () => {
  const [activeIndex, setActiveIndex] = useState(1);

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  const data = [
    {
      id: 1,
      sideTitle: (
        <>
          <span>
            <span className="mb-4 clr_mar">1</span>  Experience And Expertise
          </span>
        </>
      ),
      title: (
        <>
          <span>
            Experience And Expertise
          </span>
        </>
      ),
      description: (
        <>
          <span className="d-block mb-2">
            At Uniglobe Marketing, we bring years of industry experience and in-depth expertise to every project. Our team consists of digital marketing specialists who are not only skilled in the latest strategies but also deeply understand market trends.

          </span>
          <span className="d-block">
            We craft tailored solutions that drive growth, ensuring your business stays ahead in the competitive digital landscape. Trust us to leverage our knowledge for your success.
          </span>
        </>
      ),

    },
    {
      id: 2,
      sideTitle: (
        <>
          <span>
            <span className="mb-4 clr_mar">2</span>  Customized Solutions
          </span>
        </>
      ),
      title: (
        <>
          <span>
            Customized Solutions
          </span>
        </>
      ),
      description: (
        <>
          <span className="d-block mb-2">
            At Uniglobe, we understand that every business is unique. That's why we offer customized solutions tailored specifically to your goals and needs. Our expert team works closely with you to create personalized strategies that drive growth, enhance brand visibility, and deliver measurable results.

          </span>
          <span className="d-block">
            Whether it's digital marketing, SEO, or social media management, we craft solutions that work for you, ensuring maximum impact and success in your industry.
          </span>
        </>
      ),
    },
    {
      id: 3,
      sideTitle: (
        <>
          <span>
            <span className="mb-4 clr_mar">3</span>  Trust and Reliability
          </span>
        </>
      ),
      title: (
        <>
          <span>
            Trust and Reliability
          </span>
        </>
      ),
      description: (
        <>
          <span className="d-block mb-2">
            At Uniglobe Marketing, trust and reliability are the foundation of our services. We prioritize building long-term relationships with our clients by delivering consistent, high-quality results. With a proven track record and a transparent approach, we ensure your brand's success through dependable strategies.

          </span>
          <span className="d-block">
            Our commitment to excellence guarantees that your business is in safe hands, empowering you to achieve your marketing goals with confidence.
          </span>
        </>
      ),
    },
    {
      id: 4,
      sideTitle: (
        <>
          <span>
            <span className="mb-4 clr_mar">4</span> High Quality Services
          </span>
        </>
      ),
      title: (
        <>
          <span>
            High Quality Services
          </span>
        </>
      ),
      description: (
        <>
          <>
            <span className="d-block mb-2">
              At Uniglobe Marketing, we are committed to delivering high-quality services tailored to meet your business goals. Our team of experts ensures every strategy is backed by data, innovation, and industry best practices.

            </span>
            <span className="d-block">
              From SEO to social media marketing, we provide solutions that drive real results and help your brand stand out. With Uniglobe, quality is at the core of everything we do, ensuring your success at every step.
            </span>
          </>
        </>
      ),
    },
  ];

  return (
    <div className="bg_about p-lg-5 p-3" style={{ backgroundPosition: "right" }}
    id="experties" 
    >
      <Container className="pt-5 pb-lg-5 pb-0">
        <h2 className="text-center mar_head mb-4" data-aos="fade-down">Why Choose Uniglobe Marketing?</h2>
        <Row className="mt-4 align-items-center justify-content-center">
          {/* <div className="box_choose gap-5 mt-4"> */}
          {/* <div className="col-lg-12 gap-5 mt-4"> */}
          <div className="col-lg-4 col-12  align-items-start" data-aos="fade-up">
            {data.map((item) => (
              <div
                key={item.id}
                onClick={() => handleClick(item.id)}
                className={`py-2 px-2 bor_mar`}
                // className={`py-2 px-3 border 
                // ${activeIndex === item.id ? "border-primary" : "border-light"
                //   }`
                // }
                style={{
                  cursor: "pointer",
                  // writingMode: "vertical-rl",
                  // textAlign: "center",
                }}
              >
                {item.sideTitle}
              </div>
            ))}
          </div>
          <div className="col-lg-4 col-12" data-aos="fade-down">
          {data.map(
            (item) =>
              activeIndex === item.id && (
                <div key={item.id}>
                  {/* <h3>{item.id}</h3> */}
                  <h4>{item.title}</h4>
                  <p className="para_mar mb-0">{item.description}</p>
                </div>
              )
          )}
          {/* </div> */}
        </div>
          {/* <div className="box_choose gap-5 mt-4">
            {data.map(
              (item) =>
                activeIndex === item.id && (
                  <div key={item.id} className="content-div">
                    <h3>{item.id}</h3>
                    <h4>{item.title}</h4>
                    <p className="para_mar">{item.description}</p>
                  </div>
                )
            )}
            <div className="d-flex align-items-start side-div">
              {data.map((item) => (
                <div
                  key={item.id}
                  onClick={() => handleClick(item.id)}
                  className={`py-2 px-2 bor_mar`}
                  style={{
                    cursor: "pointer",
                    writingMode: "vertical-rl",
                  }}
                >
                  {item.sideTitle}
                </div>
              ))}
            </div>
          </div> */}

        </Row>
      </Container>
    </div>
  );
};

export default WhyChoose;

