import React, { useEffect, useState } from "react";
// import plot from "../../../data/property.plots.json";
import { toast } from "react-hot-toast";
import { FaCopy } from "react-icons/fa";
import Table from 'react-bootstrap/Table';

const PaymentMethod = ({ form, setForm, nextStep, setBooking }) => {
  const [plot, setPlot] = useState([])
  const [selectedPlot, setSelectedPlot] = useState({})
  // console.log("selectedPlotselectedPlot",selectedPlot)
  const walletAddress = "0xAc99F2Cce27a4eB805644373ed27E59B1Bf4bB7F"; // Dynamic wallet address

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success("Wallet address copied to clipboard!");
    });
  };

  const handleCheckout = (event) => {
    event.preventDefault();
    if (!form.paymentMethod) {
      toast.error("Please select a payment method.");
      return;
    }
    if (!form.paymentType) {
      toast.error("Please select a payment type.");
      return;
    }
    if (form.paymentType === "Installment" && !form.plot) {
      toast.error("Please select a plot size for installment.");
      return;
    }
    // if (!form.preferredArea) {
    //   alert("Please select a preferred area.");
    //   return;
    // }
    nextStep();
  };

  const handleIndex = () => {
    switch (form.plot) {
      case 3:
        return 0;
      case 4:
        return 1;
      case 5:
        return 2;
      case 10:
        return 3;
      default:
        return 0;
    }
  };

  const handleGetPrice = async () => {
    try {
      const baseURL = process.env.REACT_APP_BASE_URL;
      const response = await fetch(`${baseURL}/api/vrc/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      // console.log("responseresponseresponse GET",response.json())
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (!data?.data || !Array.isArray(data.data)) {
        throw new Error("Unexpected API response structure.");
      }
      setPlot(data.data)
      // setCoinPrice(price);
    } catch (error) {
      toast.error(`Payment Plan not found ${form.plot} with VRC`);
    }
  };
  const selectedPlotFun = (marla) => {
    // console.log("plot in fun",marla)
    const plotData = plot.filter((item) => parseInt(item.marla) === parseInt(marla))[0];
    // console.log("plotDataplotDataplotData",plotData)
    if (!plotData || !plotData.payment_plans) {
      throw new Error("Plot data or payment plans not found.");
    }
    setSelectedPlot(plotData)
  }

  useEffect(() => {
    handleGetPrice();
  }, []);

  // useEffect(() => {
  //   console.log("plot.length > 0 && form.plot",plot.data.length > 0,plot)
  //   if (plot.data.length > 0) {
  //     selectedPlotFun(plot);
  //   }
  // }, [form.marla , plot]);

  useEffect(() => {
    // console.log("plot useeffect", plot,plot && plot.data && plot.data.length > 0);
    if (plot.length > 0) {
      selectedPlotFun(form.plot);
    }
  }, [form.plot]);



  // useEffect(() => {
  //   console.log("plot.length > 0 && form.plot",plot.data.length > 0,plot)
  //   if (plot.length > 0){
  //   selectedPlotFun(form.plot);
  // }
  // }, [form.marla])

  // console.log("==========", selectedPlot)

  return (
    <form className="p-lg-4 p-0" onSubmit={handleCheckout}>
      {/* Choose Payment Method */}
      {/* <div className="mb-4">
        <label className="form-label fw-bold">Choose Payment Method:</label>
        <div className="d-flex gap-4">
          <div className="form-check">
            <input
              type="radio"
              id="paymentMethodPKR"
              name="paymentMethod"
              value="PKR"
              className="form-check-input"
              checked={form.paymentMethod === "PKR"}
              onChange={(e) =>
                setForm({ ...form, paymentMethod: e.target.value })
              }
            />
            <label className="form-check-label" htmlFor="paymentMethodPKR">
              Pakistan Rupees (PKR)
            </label>
          </div>
          <div className="form-check">
            <input
              type="radio"
              id="paymentMethodVRC"
              name="paymentMethod"
              value="VRC"
              className="form-check-input"
              checked={form.paymentMethod === "VRC"}
              onChange={(e) =>
                setForm({ ...form, paymentMethod: e.target.value })
              }
            />
            <label className="form-check-label" htmlFor="paymentMethodVRC">
              Virtual Coin (VRC)
            </label>
          </div>
        </div>
      </div> */}

      {/* Choose Plot Size */}
      <div className="mb-4">
        <label className="form-label fw-bold">Choose Plot:</label>
        <div className="d-flex gap-lg-4 gap-2">
          {["3", "4", "5", "10"].map((size) => (
            <div className="form-check" key={size}>
              <input
                type="radio"
                id={`plotSize${size}Marla`}
                name="plotSize"
                value={size}
                className="form-check-input"
                checked={form.plot === parseInt(size)}
                onChange={(e) =>
                  setForm({ ...form, plot: parseInt(e.target.value) })
                }
                required
              />
              <label
                className="form-check-label"
                htmlFor={`plotSize${size}Marla`}
              >
                {size} Marla
              </label>
            </div>
          ))}
        </div>
      </div>

      {/* Preferred Area */}
      {/* <label className="form-label d-block fw-bold">Preferred Area</label>
      <div className="d-flex gap-3 mb-3">
        {["Corner Plot", "Facing Park", "General"].map((preference) => (
          <div className="form-check" key={preference}>
            <input
              className="form-check-input"
              type="radio"
              id={preference}
              name="preferredArea"
              value={preference}
              checked={form.preferredArea === preference}
              onChange={(e) =>
                setForm({ ...form, preferredArea: e.target.value })
              }
              required
            />
            <label className="form-check-label" htmlFor={preference}>
              {preference}
            </label>
          </div>
        ))}
      </div> */}

      {/* Payment Type */}
      <div className="mb-4">
        <label className="form-label fw-bold">How do you want to pay?</label>
        <div className="d-flex gap-4">
          <div className="form-check">
            <input
              type="radio"
              id="paymentTypeFull"
              name="paymentType"
              value="Full Payment"
              className="form-check-input"
              checked={form.paymentType === "Full Payment"}
              onChange={(e) =>
                setForm({ ...form, paymentType: e.target.value })
              }
              required
            />
            <label className="form-check-label" htmlFor="paymentTypeFull">
              Full Payment
            </label>
          </div>
          <div className="form-check">
            <input
              type="radio"
              id="paymentTypeInstallment"
              name="paymentType"
              value="Installment"
              className="form-check-input"
              checked={form.paymentType === "Installment"}
              onChange={(e) =>
                setForm({ ...form, paymentType: e.target.value })
              }
            />
            <label
              className="form-check-label"
              htmlFor="paymentTypeInstallment"
            >
              Installment
            </label>
          </div>
        </div>
      </div>

      {/* Installment Plan Summary */}
      {!!form.plot && !!selectedPlot && (
        // <div className="mb-4">
        //   <h3 className="fw-bold mb-3">Installment Plan Summary (5 years)</h3>
        //   <table className="table table-bordered">
        //     <thead className="table-primary">
        //       <tr>
        //         <th>Size</th>
        //         <th>Down Payment</th>
        //         <th>confirmation</th>
        //         <th>Monthly Installment</th>
        //         <th>Semi-Annual Installment</th>
        //         <th>possession</th>
        //         <th>Total Price</th>
        //       </tr>
        //     </thead>
        //     <tbody>
        //       <tr>
        //         <td>{selectedPlot?.marla || ""} Marla</td>
        //         <td>{selectedPlot?.payment_plans?.down_payment}</td>
        //         <td>{selectedPlot?.payment_plans?.confirmation}</td>
        //         <td>{selectedPlot?.payment_plans?.monthly_installment}</td>
        //         <td>
        //           {selectedPlot?.payment_plans?.semi_annual_installment}
        //         </td>
        //         <td>{selectedPlot?.payment_plans?.possession}</td>
        //         <td>{selectedPlot?.payment_plans?.total_price}</td>
        //       </tr>
        //     </tbody>
        //   </table>
        // </div>

        <>
        <h3 className="fw-bold mb-2">Installment Plan Summary (5 years)</h3>
        <Table bordered responsive>
          <thead className="table-primary">
            <tr>
              <th>Size</th>
              <th>Down Payment</th>
              <th>Confirmation</th>
              <th>Monthly Installment</th>
              <th>Semi-Annual Installment</th>
              <th>Possession</th>
              <th>Total Price</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{selectedPlot?.marla || ""} Marla</td>
              <td>{selectedPlot?.payment_plans?.down_payment}</td>
              <td>{selectedPlot?.payment_plans?.confirmation}</td>
              <td>{selectedPlot?.payment_plans?.monthly_installment}</td>
              <td>
                {selectedPlot?.payment_plans?.semi_annual_installment}
              </td>
              <td>{selectedPlot?.payment_plans?.possession}</td>
              <td>{selectedPlot?.payment_plans?.total_price}</td>
            </tr>
          </tbody>
        </Table>
        </>
      )}

      {/* Wallet Address for VRC */}
      {form.paymentMethod === "VRC" && (
        <div className="mb-4">
          <label className="form-label fw-bold">Wallet Address:</label>
          <div className="d-flex gap-1 align-items-center">
            <input
              type="text"
              value={walletAddress}
              className="form-control"
              disabled
              style={{height:"44px"}}
            />
            <button
              type="button"
              onClick={() => copyToClipboard(walletAddress)}
              className="btn btn-uni-main"
            >
              <FaCopy />
            </button>
          </div>
        </div>
      )}

      {/* Buttons */}
      <div className="d-flex gap-4 justify-content-between">
        <button
          type="button"
          className="btn btn-secondary w-100 fs-5"
          onClick={() => {
            setForm({ ...form, selectedPlot: null });
            setBooking(false);
          }}
        >
          Close
        </button>
        <button type="submit" className="btn btn-uni-main w-100">
          Checkout
        </button>
      </div>
    </form>
  );
};

export default PaymentMethod;
