import React, { useEffect, useState } from "react";
import InquiryForm from "./InquiryForm";
import bg1 from "../../assets/unihomes/1.jpg";
import bg2 from "../../assets/unihomes/2.jpg";
import bg3 from "../../assets/unihomes/3.jpg";
import Booking from "./booking/Booking";
import CustomModal from "../../common/modal/CustomModal";

const HeroSection = () => {
  const [booking, setBooking] = useState(false);
  const [activeBgIndex, setActiveBgIndex] = useState(0);
  const [isFading, setIsFading] = useState(false); // Track fade effect
  const backgrounds = [bg1, bg2, bg3];

  // Update background index at intervals with fade effect
  useEffect(() => {
    const interval = setInterval(() => {
      setIsFading(true); // Trigger fade-out
      setTimeout(() => {
        setActiveBgIndex((prevIndex) => (prevIndex + 1) % backgrounds.length);
        setIsFading(false); // Trigger fade-in
      }, 800); // Match the fade-out duration in CSS
    }, 4000); // Total time for fade + display duration
    return () => clearInterval(interval);
  }, [backgrounds.length]);
  

  return (
    <>
      <div
        className={`hero-section-div ${isFading ? "fade-out" : "fade-in"}`}
        style={{
          backgroundImage: `url(${backgrounds[activeBgIndex]})`,
        }}
      >
        <div className="container mt-5">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-7 col-12 mb-3 text-white">
              <h1 className="heading-uni-main">
                Your Land, Your <br className="d-lg-block d-none"/> Legacy
              </h1>
              <p className="fs-4 mb-3">
                Let’s make your dream home a reality with <br className="d-lg-block d-none"/> University Homes
              </p>
              <button
                className="btn-uni-main px-5 p-2 border-0"
                onClick={() => setBooking(true)}
              >
                Book Your Plot
              </button>
            </div>
            <div className="col-lg-5 col-12">
              <InquiryForm />
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        title="Property Booking Form (Through VRC)"
        isOpen={booking}
        onClose={() => setBooking(false)}
      >
        <Booking setBooking={setBooking} />
      </CustomModal>
    </>
  );
};

export default HeroSection;
