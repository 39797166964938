import React, { Suspense } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../common/navbar/Navbar";
import HeroSection from "../components/unihomes/HeroSection";
import { FaWhatsapp } from "react-icons/fa";

// Lazy load other components
const ProjectOverview = React.lazy(() =>
  import("../components/unihomes/ProjectOverview")
);
const LocationHighlight = React.lazy(() =>
  import("../components/unihomes/LocationHighlight")
);
const Facilities = React.lazy(() =>
  import("../components/unihomes/Facilities")
);
const PaymentPlan = React.lazy(() =>
  import("../components/unihomes/PaymentPlan")
);
const Gallery = React.lazy(() => import("../components/unihomes/Gallery"));
// const Video = React.lazy(() => import("../components/unihomes/Video"));  // Uncomment if you need it
const Footer = React.lazy(() => import("../common/footer/Footer"));

const UniHomes = () => {
  return (
    <>
      <Helmet>
        <title>UniHomes - University Homes</title>
        <link rel="icon" href="/favicon2.ico" />
      </Helmet>
      <Navbar />
      <HeroSection />
      <Suspense fallback={<div>Loading...</div>}>
        <ProjectOverview />
        <LocationHighlight />
        <Facilities />
        <PaymentPlan />
        <Gallery />
        <Footer />
      </Suspense>
      <a
        href="https://wa.me/+923266619997" 
        target="_blank"
        rel="noopener noreferrer"
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          backgroundColor: "#25D366",
          color: "#fff",
          borderRadius: "50%",
          width: "60px",
          height: "60px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          zIndex: 1000,
        }}
      >
        <FaWhatsapp className="fs-1" />
      </a>
    </>
  );
};

export default UniHomes;
