import React from "react";
import { Modal } from "react-bootstrap";

const CustomModal = ({ title, isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <Modal centered size="lg" show={isOpen} onHide={onClose} className="mt-5">
      <Modal.Header closeButton>
        <Modal.Title className="model_tit">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default CustomModal;
