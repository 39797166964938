import React from 'react';
import icon1 from "../../../assets/uniglobemar/icons/laptop_413114.png"
import icon2 from "../../../assets/uniglobemar/icons/project-management_1087927.png"
import icon3 from "../../../assets/uniglobemar/icons/teamwork_3131333.png"
import icon4 from "../../../assets/uniglobemar/icons/transparency_1355174.png"

function StatsSection() {
  return (
    <div className="text-dark py-5 mb-3" style={{ backgroundColor: "#F6F4F9" }}
      id="business"
    >
      <h5 className='mar_head mt-lg-5 mt-3 mb-lg-5  text-center' data-aos="fade-up">How We Run Your Business</h5>
      <div className="container">
        <div className="row text-center mt-5">
          <div className="col-md-3 golden-border p-3" data-aos="fade-up">
            <img src={icon1} alt="" className='mb-3 zoom-icon' />
            <h2 className="fw-bold text_mar">Results-Driven</h2>
            <p className='mb-0'>Focused on delivering measurable  <br className='d-lg-block d-none' /> outcomes to drive your success</p>
          </div>
          <div className="col-md-3 golden-border p-3" data-aos="fade-up">
            <img src={icon2} alt="" className='mb-3 zoom-icon' />
            <h2 className="fw-bold text_mar">Innovation</h2>
            <p className='mb-0'>Driving growth through cutting-edge strategies  and creative digital solutions </p>
          </div>
          <div className="col-md-3 golden-border p-3" data-aos="fade-up">
            <img src={icon3} alt="" className='mb-3 zoom-icon' />
            <h2 className="fw-bold text_mar">Collaboration</h2>
            <p className='mb-0'>Building strong partnerships to achieve
              <br className='d-lg-block d-none' />  shared goals and success</p>
          </div>
          <div className="col-md-3 p-3" data-aos="fade-up">
            <img src={icon4} alt="" className='mb-3 zoom-icon' />
            <h2 className="fw-bold text_mar">Transparency</h2>
            <p className='mb-0'>Clear strategies, open communication,
              <br className='d-lg-block d-none' /> and measurable results every step</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatsSection;
