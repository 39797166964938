import React from "react";
import { CgMenuLeft } from "react-icons/cg";
import { AiOutlineClose } from "react-icons/ai";

import unilogo from "../../assets/unihomes/Marketed By Uniglobe-02.svg";
import { Link } from "react-router-dom";
import { FiPhoneCall } from "react-icons/fi";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);

  const navLinks = [
    { name: "Project Overview", href: "#projects-overview" },
    { name: "Location", href: "#location-highlight" },
    { name: "Facilities & Amenities", href: "#facilities&amenities" },
    { name: "Payment Plan", href: "#payment-plan" },
    { name: "Gallery", href: "#gallery" },
  ];

  return (
    <nav className="position-fixed w-100 navbar navbar-expand-lg navbar-uni-bg p-1" style={{ zIndex: '10000', height: "70px" }}>
      <div className="container">
        <Link to="/universityhomes">
          <img
            src={require("../../assets/unihomes/logo.png")}
            alt="Uniglobe Marketing"
            className="logo-uni"
          />
        </Link>

        {/* <CgMenuLeft
          className="mt-1 ms-3 d-lg-none d-block"
          color="#fff"
          cursor="pointer"
          fontSize={27}
          onClick={() => setToggleMenu(true)}
        />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <AiOutlineClose
              fontSize={27}
              className="overlay__close"
              onClick={() => setToggleMenu(false)}
            />
            <ul className="app__navbar-smallscreen_links">
              <li>
                <a href="" onClick={() => setToggleMenu(false)}>
                  Home
                </a>
              </li>
              <li>
                <a href="" onClick={() => setToggleMenu(false)}>
                  Our Projects
                </a>
              </li>
              <li>
                <a href="" onClick={() => setToggleMenu(false)}>
                  About Us
                </a>
              </li>
              <li>
                <a href="" onClick={() => setToggleMenu(false)}>
                  Explore the Society
                </a>
              </li>
              <li>
                <a href="" onClick={() => setToggleMenu(false)}>
                  Our Service
                </a>
              </li>
              <li className="p__opensans">
                <a href="" onClick={() => setToggleMenu(false)}>
                  Testimonials
                </a>
              </li>
            </ul>
          </div>
        )} */}

        {/* Collapsible menu */}
        <div className="collapse navbar-collapse" id="navbarNav">
          <div className="d-flex w-100 justify-content-between align-items-center">
            {/* Dynamically Rendered Navigation Links */}
            <ul className="navbar-nav mx-auto gap-1">
              {navLinks.map((link, index) => (
                <li className="nav-item" key={index}>
                  <a className="nav-link text-white nav_text" href={link.href}>
                    {link.name}
                  </a>
                </li>
              ))}
            </ul>

            <a href="tel:03266619997">
              <p className="text-white mb-0 me-3">
                <FiPhoneCall className="me-1" />
                +92 326 6619997</p>
            </a>
              <img
                style={{
                  height: 50,
                }}
                src={unilogo}
                alt=""
              />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
